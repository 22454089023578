import React from 'react';
import styled from 'styled-components';

const DivTop = styled.div`
    width: 100%;
    margin: auto;
    // margin-top: 100px;
    background: #f7f3eb;
    padding-top: 50px;
    padding-bottom: 50px;
`;

const NewsButtons = styled.button`
    padding-top: 10%;
    padding-bottom: 10%;
    
    // background: linear-gradient(to right, #1abc9c, #2ecc71);
    background: linear-gradient(to right, #9b003f, #c0392b, #e74c3c, #f39c12);

    color: white;
`;


const Iframe = styled.iframe`
    width: 100%;
    height :200px;
    overflow: visible;
`;

const TextOutLine = styled.h4`
    color: #9b003f;
    // text-shadow:
    //   -1px -1px 0 #2e8b57,  
    //   1px -1px 0 #2e8b57,
    //   -1px 1px 0 #2e8b57,
    //   1px 1px 0 #2e8b57;
`
const IframeDiv = styled.div`
    // background: #3f7f88;
    width: 90%;
    margin: auto;
`
const ImgSummer = styled.img`
`;

const Topics = () => {
    return (
        <DivTop>
            <section className="w-11/12 mx-auto text-gray-600 body-font relative px-4 sm:px-0" style={{marginBottom:'6.25rem'}}>
                <div className="container mx-auto mb-11">
                    <div className="flex flex-wrap -m-4">
                    <a href="https://tosayobi.ac.jp/kouza/index.php" className="mx-auto p-4 md:w-1/2 w-full">
                        <NewsButtons className="w-full  text-2xl md:text-3xl text-white font-bold py-3 px-4 border-b-4  rounded">
                        冬期講習会の申込はこちら<span class="material-symbols-outlined">chevron_right</span>
                        </NewsButtons>

                        {/* 講座案内画像を表示 */}
                        {/* <ImgSummer src="https://tosayobi.ac.jp/images/top.jpg" /> */}
                    </a>
                    </div>
                </div>
            </section>
            <IframeDiv>
                <TextOutLine className='text-3xl md:text-6xl tracking-widest'>TOPICS</TextOutLine>
                <Iframe src="https://tosayobi.ac.jp/Topics/index.php" frameborder="0"></Iframe>
            </IframeDiv>

        </DivTop>
    );
}

export default Topics;